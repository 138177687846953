<template>
  <b-container class="p-0" fluid='lg' >
    <h3>
      {{ user.email }}
    </h3>
    <small class="text-muted">ID {{profile._id}}</small>
    <p>
      <b-icon v-if="isLoading" icon="arrow-clockwise" animation="spin"></b-icon>
    </p>
    <b-row :key="isLoading">
      <b-col cols="12" md="6" class="my-2">
        <b-card class="h-100">
          <p>
            <small>Full Name</small><br />
            {{ profile.name }}
          </p>
          <p>
            <small>Phone</small><br />
            {{ profile.phone ? profile.phone : "n/a" }}
          </p>
          <p v-if="profile.address">
            <small>Address</small><br />
            {{ profile.address.street1 ? profile.address.street1 : "n/a"
            }}<br />
            {{ profile.address.state }} {{ profile.address.zipcode }}<br />
            {{ profile.address.country }}
          </p>
          <p class="text-right">
            <b-button
              size="sm"
              variant="primary"
              class=""
              @click="profileModal = !profileModal"
            >
              <b-icon-pencil></b-icon-pencil> EDIT
            </b-button>
          </p>
          <hr />
          <p v-if="profile.kyc">
            <small>SSN</small><br />
            {{
              profile.kyc.ssn
                ? " ### - ####- " + profile.kyc.ssn.slice(-4)
                : "n/a"
            }}
          </p>
          <p v-if="profile.kyc">
            <small>DOB</small><br />
            {{ profile.kyc.dob ? profile.kyc.dob : "n/a" }}
          </p>
          <p class="text-right">
            <b-button size="sm" variant="primary" @click="kycModal = !kycModal">
              <b-icon-pencil></b-icon-pencil> EDIT
            </b-button>
          </p>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" class="my-2">
        <b-card class="h-100">
          <p>
            <small>Default Bank</small>
          </p>
          <h4 v-if="defaultBank">
            {{ defaultBank.bankName ? defaultBank.bankName : "n/a" }} -
            {{ defaultBank.account ? defaultBank.account.slice(-4) : "n/a" }}
          </h4>
          <b-list-group flush>
            <b-list-group-item class="d-flex justify-content-between">
              <div>
                <small v-if="profile.banks.length == 0"
                  >No Bank Account Listed</small
                >
              </div>
              <b-button
                size="sm"
                variant="primary"
                @click="bankModal = !bankModal"
              >
                <b-icon-plus></b-icon-plus> BANK
              </b-button>
            </b-list-group-item>
            <b-list-group-item v-for="bank in activeBanks" :key="bank._id">
              <div>
                <div
                  v-if="!bank.isDefault"
                  class="d-flex justify-content-between align-items-right"
                >
                  {{ bank.bankName ? bank.bankName : "n/a" }} -
                  {{ bank.account ? bank.account.slice(-4) : "n/a" }}
                  <div class="d-flex justify-content-end">
                    <b-button
                      size="sm"
                      variant="outline-secondary"
                      @click="deactiveBank(bank._id)"
                      class="mr-4"
                    >
                      <b-icon-x></b-icon-x>
                    </b-button>
                    <b-button
                      size="sm"
                      variant="outline-primary"
                      @click="setDefaultBank(bank._id)"
                    >
                      Default
                    </b-button>
                  </div>
                </div>
                <div
                  v-else
                  class="d-flex justify-content-between align-items-right"
                >
                  {{ bank.bankName ? bank.bankName : "n/a" }} -
                  {{ bank.account ? bank.account.slice(-4) : "n/a" }}
                  <div class="text-seconary">Default Bank Account</div>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>

    <!-- profile modal form -->
    <b-modal v-model="profileModal" centered>
      <template #modal-title>
        Profile
      </template>

      <b-form>
        <b-form-group label="Full Name" label-for="name">
          <b-form-input id="name" v-model="newProfile.name"> </b-form-input>
        </b-form-group>
        <b-form-group label="Phone" label-for="phone">
          <b-form-input type="tel" id="phone" v-model="newProfile.phone">
          </b-form-input>
        </b-form-group>
        <b-form-group label="Street and Bulid #" label-for="stree1">
          <b-form-input
            required
            id="stree1"
            v-model="newProfile.address.street1"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label="Zipcode" label-for="zipcode">
          <b-form-input id="Zipcode" v-model="newProfile.address.zipcode">
          </b-form-input>
        </b-form-group>
        <b-form-group label="State" label-for="state">
          <b-form-input id="state" v-model="newProfile.address.state">
          </b-form-input>
        </b-form-group>
        <b-form-group label="Country" label-for="country">
          <b-form-input id="country" v-model="newProfile.address.country">
          </b-form-input>
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <b-button @click="profileModal = !profileModal">
          Cancel
        </b-button>
        <b-button
          @click="updateProfile"
          :disabled="isLoading"
          variant="primary"
        >
          Submit
        </b-button>
      </template>
    </b-modal>

    <!-- kyc modal form -->
    <b-modal v-model="kycModal" centered>
      <template #modal-title>
        KYC
      </template>

      <b-form>
        <b-form-group label="SSN" label-for="ssn">
          <b-form-input :required="true" id="SSN" v-model="newProfile.kyc.ssn">
          </b-form-input>
        </b-form-group>
        <b-form-group label="Date of Birth" label-for="dob">
          <b-form-input type="date" id="dob" v-model="newProfile.kyc.dob">
          </b-form-input>
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <b-button @click="kycModal = !kycModal">
          Cancel
        </b-button>
        <b-button
          @click="updateProfile"
          variant="primary"
          :disabled="isLoading"
        >
          Submit
        </b-button>
      </template>
    </b-modal>

    <!--bank modal form -->
    <b-modal v-model="bankModal" centered>
      <template #modal-title>
        New Bank Account
      </template>

      <b-form>
        <b-form-group label="Bank Name" label-for="name">
          <b-form-input :required="true" id="name" v-model="newBank.bankName">
          </b-form-input>
        </b-form-group>
        <b-form-group label="Routing No." label-for="routing">
          <b-form-input id="routing" v-model="newBank.routing"> </b-form-input>
        </b-form-group>
        <b-form-group label="Account No." label-for="account">
          <b-form-input id="account" v-model="newBank.account"> </b-form-input>
        </b-form-group>

        <b-form-group>
          <b-form-radio-group
            v-model="newBank.accountType"
            :options="['Checking', 'Saving']"
            name="radio-inline"
          >
          </b-form-radio-group>
        </b-form-group>

        <b-form-group class="my-4">
          <b-form-checkbox
            v-model="newBank.isDefault"
            :disabled="initDefaultBank"
          >
            Default Bank?
          </b-form-checkbox>
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <b-button @click="bankModal = !bankModal">
          Cancel
        </b-button>
        <b-button @click="addBank" variant="primary" :disabled="isLoading">
          Submit
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
const axios = require("axios");
//const { ProfileSchema } = require("../../functions/profile/profile_model");

export default {
  data() {
    return {
      profile: {},
      newProfile: {},
      newBank: {
        bankName: null,
        routing: null,
        account: null,
        accountType: "Checking",
        isDefault: this.initDefaultBank,
        isActive: true
      },
      profileModal: false,
      kycModal: false,
      bankModal: false,
      isLoading: false,
      error: null
    };
  },
  computed: {
    ...mapGetters("user", {
      isLoggedIn: "getUserStatus",
      user: "getUser"
    }),
    initDefaultBank() {
      //if no default bank find, the new bank account is set as default (no-editable)
      return this.profile.banks.findIndex(e => e.isDefault == true) == -1;
    },
    activeBanks() {
      return this.profile.banks.filter(e => {
        return e.isActive;
      });
    },
    defaultBank() {
      return this.profile.banks.filter(e => {
        return e.isDefault;
      })[0];
    }
  },
  methods: {
    updateProfile: function() {
      this.isLoading = true;
      this.profile = Object.assign(this.profile, this.newProfile);
      axios
        .post("/.netlify/functions/profile", this.profile)
        .then(res => {
          console.log(res.data);
          this.profile = res.data.updatedDoc;
          this.newProfile = JSON.parse(JSON.stringify(this.profile)); //removed any deep link between new and profile
        })
        .catch(err => {
          this.error = err;
        })
        .finally(() => {
          this.isLoading = false;
          this.profileModal = false;
          this.kycModal = false;
        });
    },
    addBank() {
      this.isLoading = true;
      this.newBank.isActive = true;
      if (this.newBank.isDefault)
        this.profile.banks.forEach(e => {
          e.isDefault = false;
        });

      this.profile.banks.push(this.newBank);
      axios
        .post("/.netlify/functions/profile", this.profile)
        .then(res => {
          console.log(res.data);
          this.newBank = {};
          this.profile = res.data.updatedDoc;
        })
        .catch(err => {
          this.error = err;
        })
        .finally(() => {
          this.isLoading = false;
          this.bankModal = false;
        });
    },
    setDefaultBank(bankId) {
      this.isLoading = true;
      this.profile.banks.forEach(e => {
        if (e._id == bankId) {
          e.isDefault = true;
        } else e.isDefault = false;
      });
      axios
        .post("/.netlify/functions/profile", this.profile)
        .then(res => {
          console.log(res.data);
          this.profile = res.data.updatedDoc;
        })
        .catch(err => {
          this.error = err;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deactiveBank(bankId) {
      this.isLoading = true;
      this.profile.banks.forEach(e => {
        if (e._id == bankId) {
          e.isActive = false;
          e.isDefault = false;
        }
      });
      axios
        .post("/.netlify/functions/profile", this.profile)
        .then(res => {
          console.log(res.data);
          this.profile = res.data.updatedDoc;
        })
        .catch(err => {
          this.error = err;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },
  async created() {
    this.isLoading = true;
    axios
      .get("/.netlify/functions/profile")
      .then(res => {
        this.profile = res.data ? res.data : {};
        this.profile.name = this.profile.name
          ? this.profile.name
          : this.user.username;
        this.newProfile = Object.assign(this.newProfile, this.profile); //copy from profile, may cause filed lost too.
        console.log(this.newProfile);
      })
      .catch(err => {
        this.error = err;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
};
</script>
